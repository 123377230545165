<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Annonse
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Ved å annonsere i våre publikasjoner treffer du beslutningstakerne i den utvidede fornybarbransjen i Norge. Vi leverer høyt verdsatt journalistikk til våre svært lojale lesere.
          </p>
          <p>
            Ved å profilere ditt selskap og kommunisere ditt budskap på våre kommunikasjonsflater treffer du de riktige menneskene og selskapene i hele verdikjeden.
          </p>
          <p>
            For nærmere informasjon kontakt oss på: <a href="mailto:annonse@europower.no">annonse@europower.no</a> eller 22001150, så hjelper vi deg med å finne gode løsninger.
          </p>
          <p>
            Europower er den klart ledende og viktigst mediekanalen for kraft- og fornybar energi i Norge.
          </p>
          <p>
            Du finner oss: på alle bord – på alle nivå – landet rundt.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'advertise-page',
  extends: Page
};
</script>
